.res-col {
    text-align: left;
    padding-right: 30px;
    padding-left: 30px;
    height: min-content;
    min-height: 450px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.res-col2 {
    text-align: left;
    padding-right: 30px;
    padding-left: 30px;
    min-height: 456px;
    height: min-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.col-img {
    height: 456px; 
    width: 300px; 
    position: relative;
    vertical-align: top;
 }

.formation-images{
    width: 100% ;
    min-width: 589px;
    height: auto;
    min-height: 460px;
   margin: auto;
}

@media screen and (max-width: 500px) {

    .res-col , .res-col2 {
        height: min-content;
        font-size: 14px;
        padding: 20px;
        justify-content: center;
    }
/*    .formation-images{
        margin: auto;
        display: block;
        height: 180px;
        width: 300px;
        margin-top: 30px;
    }*/

}

@media screen and (max-width: 900px) and (min-width: 500px){
    .res-col , .res-col2 {
       
        height: min-content;
    }
}