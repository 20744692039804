.isolist {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.container {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 910px;
  padding-left: 345px;
  padding-right: 100px;
  margin: 0 auto;
  background-color: #fdfdfd;
}
.filter-isolist {
  margin-top: 45px;
  margin-bottom: 40px;
  text-align: center
}
.filter-isolist button {
  border-radius: 0;
}
.filter-container {
  margin-left: 100px;
  margin-bottom: 40px;
}
@media screen and (min-width: 1450px) {
  .filter-container {
    margin-left: 330px;
    margin-right: 300px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1450px) {
  .filter-container {
    margin-left: 70px;
  }
}
@media screen and (min-width: 450px) and (max-width: 900px) {
  .filter-container {
    margin-left: 80px;
  }
}
@media screen and (max-width: 450px) and (min-width: 375px){
  .filter-container {
    margin-left: 58px;
  }
}

@media screen and (max-width: 375px) and (min-width: 320px){
  .filter-container {
    margin-left: 37px;
  }
}

@media screen and (max-width: 320px) and (min-width: 280px){
  .filter-container {
    margin-left: 0px;
  }
}
.ant-dropdown-menu {
  width: 80%;
  margin: auto;
}