.home {
  background-color: #fdfdfd;
}
#root {
  background-color: #fdfdfd;
  overflow-x: hidden;
}
.img-apropos {
  width: auto;
  height: 300px;
  margin-top: 20px;
}
.row-apropos {
  margin-top: 55px; 
  /* margin-left: 150px;
  margin-right: 150px; */
  font-size: 16px;
}
@media screen and (max-width: 900px) {
  .row-apropos {
    margin-left: 40px;
    margin-right: 40px;
  }
  .row-apropos p {
    font-size: 15px;
  }
}
@media screen and (min-width: 1450px) {
  .img-apropos {
    margin-top: 0px;
  }
  .row-apropos p {
    margin-top: 20px;
  }
}