* {
  font-family: 'Century Gothic';
}

.row-offres {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.wrapper {
  height: 100%;
  position: relative;
  max-width: 100%;
  cursor: pointer;
}
.wrapper img {
  max-width: 100%;
  height: 750px;
}
.contrast {
  height: 100%;
}

.agile-img {
  width: 100px;
  height: 100px;
}

.contrast:hover {
  filter: contrast(70%);
  -webkit-filter: contrast(70%);
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.overlay-offre {
  padding: 20px;
  width: max-content;
  background-color: transparent;
  color: white;
  position: absolute;
  font-size: 30px;
  font-weight: bold;
  top: 40%;
  left: 50%;
  text-align: center;
  overflow-wrap: break-word;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.overlay22 {
  position: absolute;
  max-width: 100%;
  width: max-content;
  padding: 20px;
  background-color: transparent;
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  top: 60%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.overlay-offre2 {
  padding: 30px;
  width: 90%;
  background-color: transparent;
  color: white;
  position: absolute;
  font-size: 42px;
  font-weight: bold;
  top: 50%;
  left: 50%;
  text-align: center;
  vertical-align: center;
  overflow-wrap: break-word;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.wrapper:hover .overlay22 {
  opacity: 1;
}
.wrapper:hover img {
  background-color: rgba(0, 0, 0, 0.4);
}

.carousel-framworks {
  text-align: center;
  line-height: 60px;
  background: transparent;
  overflow: hidden;
}

.nosoffres {
  display: flex;
  justify-content: center;
  height: 400px;
}
.nosoffres-link img {
  width: 100%;
  height: 100%;
}
.nosoffres-link {
  width: 100%;
}

.nosoffres img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nosoffres-row {
  margin-bottom: 30px;
  background-color: #063aa9;
  height: min-content;
  align-items: center;
  display: flex;
}

.nosoffres-title h1 {
  color: white;
  text-align: center;
  overflow: hidden;
  font-size: 40px;
}

.nostrainigs-title h1 {
  color: #000c17;
  text-align: center;
  overflow: hidden;
  font-size: 40px;
  padding-top: 20px;
}

.ant-progress .ant-progress-text {
    color: #063aa9 !important;
}

.row-agile {
  margin-top: 60px;
}
.logoTechno {
  padding: 5px;
}

.img-offre1 {
  background-image: url('../assets/images/Offre1.webp');
  background-image: url('../assets/images/safari/Offre1.png');
  background-position: center;
  background-size: cover;
  filter: brightness(80%);
  width: 100%;
  position: relative;
  min-height: 100%;

  height: 1000px;
}
.img-offre2 {
  background-image: url('../assets/images/Offre2.webp');
  background-image: url('../assets/images/safari/Offre2.png');
  background-position: center;
  background-size: cover;
  filter: brightness(80%);
  width: 100%;
  position: relative;
  min-height: 100%;

  height: 1000px;
}
.img-offre3 {
  background-image: url('../assets/images/Offre3.webp');
  background-image: url('../assets/images/safari/Offre3.png');
  background-position: center;
  background-size: cover;
  filter: brightness(80%);
  width: 100%;
  position: relative;
  min-height: 100%;

  height: 1000px;
}
.col-offres {
  height: 100%;
}

@media screen and (min-width: 500px) and (max-width: 1200px) {
  .row-agile {
    margin-right: 20px;
    margin-left: 20px;
  }
  .col-agile {
    font-size: 13px;
    text-align: justify;
    padding-right: 5px;
  }
  .agile-img {
    margin-right: 5px;
    margin-left: 5px;
    width: 70px;
    height: 70px;
  }
  .img-offre1 {
    background-image: url('../assets/images/Offre1-min.webp');
    background-image: url('../assets/images/safari/Offre1-min.png');
  }
  .img-offre2 {
    background-image: url('../assets/images/Offre2-min.webp');
    background-image: url('../assets/images/safari/Offre2-min.png');
  }
  .img-offre3 {
    background-image: url('../assets/images/Offre3-min.webp');
    background-image: url('../assets/images/safari/Offre3-min.png');
  }
  .overlay-offre {
    top: 50%;
    font-size: 40px;
    text-align: center;
  }
  .overlay-offre2 {
    font-size: 35px;
  }
}

@media screen and (min-width: 1450px) {
  .img-offre1,
  .img-offre2,
  .img-offre3 {
    height: 1050px;
  }
  .wrapper img {
    width: 100%;
    height: 950px;
  }
}

@media screen and (max-width: 500px) {
  .overlay-offre2 {
    font-size: 24px;
  }

  .col-offres {
    height: 33.3333333%;
  }

  .overlay-offre {
    top: 50%;
  }
}
@media screen and (max-width: 400px) {
  .overlay-offre {
    top: 50%;
  }
}

@media screen and (max-width: 500px) {
  .row-agile {
    margin-top: 0;
  }
  .ant-timeline-item-content {
    margin-left: 60px !important;
    margin-right: -10px !important;
  }
}

@media screen and (max-width: 500px) {
  .img-offre1,
  .img-offre2,
  .img-offre3 {
    height: 100%;
  }
  .overlay-offre {
    padding: 0px;
  }
  .col-agile {
    text-align: center;
    font-size: 14px;
  }
  .agile-img {
    margin-top: 50px;
  }
  .row-offres {
    height: 100vh;
  }
  .overlay-offre {
    font-size: 24px;
    text-align: center;
  }
  .logoTechno {
    padding: 20px;
  }
}
