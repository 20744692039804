.mobile-menu {
  background-color: transparent;
  position: relative;
  z-index: 997;
}
.mobile-button {
  background-color: #063AA9;
  border: none;
  position: fixed;
  top: 1%;
  right: 1%;
  margin-top: 4px;
}
.alert {
  overflow: hidden;
}
.menu-open {
  background-color: #063AA9;
  width: 100%;
  position: fixed;
  z-index: 998;
  left: 0;
  border-right: 0;
  top: 60px;
  overflow: hidden;
}
.menu-open a {
  color: white;
  text-align: center;
}
.alert-enter {
  max-height: 0px;
  opacity: 0;
}
.alert-enter-active {
  max-height: 240px;
  opacity: 1;
  transition: 1s max-height, 0.5s opacity;
}
.alert-exit {
  opacity: 1;
  max-height: 1640px;
}
.alert-exit-active {
  max-height: 0px;
  opacity: 0;
  transition: opacity 100ms, max-height 3s
}
