*{
    font-family: "Century Gothic";
}

 .site-card-wrapper {
    background: white;
    padding: 0px;
    position: relative;
    z-index: 3;
    background-color: #fdfdfd;
}
.logo {
    margin: 50px;
}
.offres-accueil {
    position: relative;
    z-index: 2;
    background-color: #fdfdfd;
}
.offres-accueil a {
    margin: 0;
}
.ant-card-body {
    text-align: center;
}
.offres-accueil .ant-card-head-title {
    text-align: center;
    white-space: break-spaces;
}
.card-offre-accueil {
    height: 570px;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
.card-offre-accueil:hover {
    box-shadow: 3px 1px 13px -1px rgba(84,81,84,1);
    cursor: pointer;
}

.card-offre-accueil-genie {
    height: 570px;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    box-shadow: 3px 1px 13px -1px rgba(84,81,84,1);
    cursor: pointer;
}
.offres-title h1{
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: white;
    position: absolute;
    top: 45%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    margin: 0;
}

.offres-title h3 {
    font-size: 20px;
    width: max-content;
    text-align: center;
    color: white;
    position: absolute;
    top: 55%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    margin: 0;
}

.wrapper {
    position: relative;
    max-width: 100%;
    cursor: pointer; 
  }
  .wrapper img {
    max-width: 100%;
  }
  .contrast:hover {
    filter: contrast(70%);
    -webkit-filter: contrast(70%);
    -moz-transition: all 0.3s ease-in-out 0s;
      -webkit-transition: all 0.3s ease-in-out 0s;
      -ms-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
  }
  .overlay1 {
    padding: 20px;
    width: max-content;
    background-color: transparent;
    color: white;
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    top: 50%;
    left: 50%;
    text-align: center;
    -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }
  .wrapper:hover img {
    background-color: rgba(0, 0, 0, 0.4)
  }
.card-offres-3 {
    font-size: 16px;
    font-style: italic;
    padding-left: 20px;
    padding-right: 20px;
}
@media screen and (max-width: 900px) {
    #col-offres {
        margin-bottom: 50px;
    }
    #card2 {
        padding-top: 27px;
    }
}
@media screen and (max-width: 400px) {
    #card-offres-accueil-1 {
        height: 620px;
    }
    #card-offres-accueil-2 {
        height: 640px
    }
    #card-offres-accueil-3 {
        height: 580px;
    }
    #card2 {
        padding-top: 5px;
    }
}
@media screen and (min-width: 401px) and (max-width: 499px) {
    #card-offres-accueil-1 {
        height: 600px;
    }
    #card-offres-accueil-2 {
        height: 640px
    }
    #card-offres-accueil-3 {
        height: 580px;
    }
}

@media screen and (min-width: 1450px) {
    #card-offres-accueil-2 .ant-card-head {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

@media screen and (max-width: 1024.9px) and (min-width: 900px){
    #card-offres-accueil-1 {
        height: 800px;
    }
    #card-offres-accueil-2 {
        height: 800px
    }
    #card-offres-accueil-3 {
        height: 800px;
    }
}

@media screen and (max-width: 1450px) and (min-width: 1024.9px){
    #card-offres-accueil-1 {
        height: 620px;
    }
    #card-offres-accueil-2 {
        height: 620px
    }
    #card-offres-accueil-3 {
        height: 620px;
    }
}

@media screen and (min-width:732px){
    #card-offres-accueil-1 {
        height: 650px;
    }
    #card-offres-accueil-2 {
        height: 650px
    }
    #card-offres-accueil-3 {
        height: 650px;
    }
}