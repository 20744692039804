.ant-timeline-item-tail {
    left: 2px;
    top: 25px;
}

.ant-timeline-item-content {
    margin: 20px 10px 0px 100px;
}

svg:not(:root).svg-inline--fa {
    margin-bottom: 10px;
    margin-top: 10px;
}

.ant-timeline-item-head-custom {
    top: 17.5px;
}