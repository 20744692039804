* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: "Century Gothic";
  src: url("./assets/CenturyGothic400.ttf")
}

html body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont,"Century Gothic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,"Century Gothic", "Courier New",
    monospace;
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after
{
   border-top: 3px solid #1E2228;
}

@font-face {
  font-family: Raleway-Regular;
  src: url('/fonts/raleway/Raleway-Regular.ttf');
}

@font-face {
  font-family: Raleway-Black;
  src: url('/fonts/raleway/Raleway-Black.ttf');
}

@font-face {
  font-family: Raleway-SemiBold;
  src: url('/fonts/raleway/Raleway-SemiBold.ttf');
}

@font-face {
  font-family: Raleway-Bold;
  src: url('/fonts/raleway/Raleway-Bold.ttf');
}
