
.footer {
  background-color: #fdfdfd;
  color: #001529;
  width: 100%;
  margin: auto;
  position: relative;
  z-index: 3;
}
.footer-top {
  padding-top: 30px;
}
/* section images */
.footer img {
  max-width: 60%;
}
@media screen and (max-width: 500px) {
  .footer img {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  #social {
    font-size: 12px;
    margin: auto;
  }
  #social p {
    font-size: 13px;
    padding-bottom: 20px;
  }
  #social svg {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

/* section liens réseaux sociaux */
#social {
  font-size: 15px;
  margin: auto;
}
#social p {
  font-size: 15px;
  padding-bottom: 20px;
}
#social svg {
  font-size: 50px;
  margin-left: 20px;
  margin-right: 20px;
}
.footer a {
  text-decoration: none;
  color: #001529;
}

/* section contact */
.table-contact {
  display: flex;
  justify-content: center;
  margin-top: -9px;
}
.tabcontact, .tabcontact td {
  font-size: 18px;
}
.tabcontact tr {
  height: 50px;
}
@media screen and (max-width: 1135px) {
  .tabcontact {
    margin: auto;
    margin-top: 30px;
  }
}

/* section menu */
.menu {
  margin-top: 30px;
  margin-bottom: 30px;
}
.menu a {
  font-size: 20px;
}
@media screen and (max-width: 900px) {
  .menu a {
    font-size: 15px;
  }
  #social {
    font-size: 15px;
    margin: auto;
  }
  #social p {
    font-size: 15px;
    padding-bottom: 20px;
  }
  #social svg {
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.ant-divider, .ant-divider-vertical {
  height: 2em;
  top: auto;
}

/* copyright */
#copyr {
  font-size: 20px;
  margin-bottom: 10px;
}
#footer-divider {
  margin-left: 50px;
  margin-right: 50px;
}
@media screen and (max-width: 500px) {
  #footer-divider {
    margin-left: 8px;
    margin-right: 8px;
  }
}
