.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: white !important; /*Overriden property*/
  border-bottom: 2px solid white;
}
.ant-layout-header {
  height: 60px;
  padding-right: 20px;
  width: 100%;
}
.logo-header {
  height: 60px; 
  position: absolute;
  z-index: 999;
  top: 0; 
  left: 20px;
}

.transHeader {
  background-color: transparent; 
  border-bottom: 1px solid gray; 
  position: fixed; 
  top: 0; 
  z-index: 999; 
  width: 100%;
  transition:0.5s
}
.blueHeader {
  background-color: #063AA9;
  position: fixed; 
  top: 0; 
  z-index: 999; 
  width: 100%;
  transition:0.5s
}
.menu-item-selected {
  padding-bottom: 3px;
  border-bottom: 2px solid #fff !important;
}