* {
  font-family: 'Century Gothic';
}

.home-bloc1 {
  position: relative;
  height: 800px;
}
.img-bloc1 {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.img-accueil {
  background-image: url('../assets/images/header.webp');
  background-image: url('../assets/images/safari/header.jpg');
  background-position: center;
  background-size: cover;
  filter: brightness(50%);
  width: 100%;
  position: fixed;
  top: 0;
  height: 800px;
}

.text-bloc1 {
  position: absolute;
  z-index: 2;
  top: 150px;
  text-align: center;
  line-height: normal;
  margin-left: 200px;
  margin-right: 200px;
}

.bandeau1 {
  background-image: url('../assets/images/Offre1.webp');
  background-image: url('../assets/images/safari/Offre1.png');
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  position: relative;
  height: 400px;
  margin-top: -25px;
}

.bandeau2 {
  background-image: url('../assets/images/Offre2.webp');
  background-image: url('../assets/images/safari/Offre2.png');
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  position: relative;
  height: 400px;
  margin-top: -25px;
}

.bandeau3 {
  background-image: url('../assets/images/Offre3.webp');
  background-image: url('../assets/images/safari/Offre3.png');
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  position: relative;
  height: 400px;
  margin-top: -25px;
}

.bandeau-text {
  position: absolute;
  z-index: 2;
  text-align: center;
  line-height: normal;
  margin-top: 140px;
  margin-left: 300px;
  margin-right: 300px;
}
.bandeau-text h1 {
  color: white;
}

.bandeau-text h3 {
  color: white;
}
.text-bloc1 h1 {
  color: white;
  margin-bottom: 50px;
  font-size: 54px;
}
.text-bloc1 h2 {
  color: white;
  margin-bottom: 10px;
  font-size: 46px;
}
.text-bloc1 h3 {
  color: white;
  margin-bottom: 50px;
  font-size: 46px;
}
.text-bloc1 h4 {
  color: white;
  margin-bottom: 50px;
  font-size: 24px;
}
#savoir-plus {
  margin-bottom: 30px;
  color: white;
  border: none;
}
.scroll-downs {
  position: relative;
  margin: auto;
  width: 34px;
  height: 55px;
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

@media screen and (max-width: 500px) {
  .home-bloc1 {
    position: relative;
    height: 900px;
  }
  .img-bloc1 img {
    height: 900px;
    width: auto;
    max-height: 100%;
    margin: auto;
    position: fixed;
    z-index: 3;
  }
  .img-accueil {
    height: 900px;
  }
  .text-bloc1 {
    margin-left: 10px;
    margin-right: 10px;
    top: 50px;
  }
  .text-bloc1 h1 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .text-bloc1 h2 {
    font-size: 25px;
  }
  .text-bloc1 h3 {
    font-size: 23px;
    margin-bottom: 30px;
  }
  .text-bloc1 h4 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .bandeau1 {
    height: 400px;
  }
  .bandeau2 {
    height: 400px;
  }
  .bandeau3 {
    height: 400px;
  }
  .bandeau-text {
    margin-left: 10px;
    margin-right: 10px;
    top: 0px;
  }
  .bandeau-text h1 {
    font-size: 30px;
  }
  .bandeau-text h3 {
    font-size: 18px;
  }
  .scroll-downs {
    margin-top: -15px;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .home-bloc1 {
    height: 1024px;
  }
  .img-accueil {
    height: 1024px;
  }
  .text-bloc1 {
    margin-left: 50px;
    margin-right: 50px;
    top: 100px;
  }
  .text-bloc1 h1 {
    font-size: 30px;
    margin-top: 50px;
  }
  .text-bloc1 h2 {
    font-size: 25px;
  }
  .text-bloc1 h3 {
    font-size: 23px;
  }
  .text-bloc1 h4 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  #savoir-plus {
    margin-bottom: 50px;
  }
  .bandeau-text {
    margin-left: 50px;
    margin-right: 50px;
    top: 0px;
  }
  .scroll-downs {
    margin-top: 20px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1450px) {
  /*    .home-bloc1 {
        height: 985px;
    }*/
  .home-bloc1 {
    height: 1024px;
  }
  .img-accueil {
    height: 1024px;
  }
  .text-bloc1 {
    margin-left: 50px;
    margin-right: 50px;
    top: 100px;
  }
  .text-bloc1 h1 {
    font-size: 54px;
    margin-top: 50px;
  }
  .text-bloc1 h2 {
    font-size: 46px;
  }
  .text-bloc1 h3 {
    font-size: 46px;
  }
  .text-bloc1 h4 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  #savoir-plus {
    margin-bottom: 50px;
  }
  .scroll-downs {
    margin-top: 75px;
  }
}
@media screen and (min-width: 1450px) {
  .home-bloc1 {
    height: 1024px;
  }
  .img-accueil {
    height: 1024px;
  }
  .text-bloc1 {
    margin-left: 50px;
    margin-right: 50px;
    top: 100px;
  }
  .text-bloc1 h1 {
    font-size: 54px;
    margin-top: 50px;
  }
  .text-bloc1 h2 {
    font-size: 46px;
  }
  .text-bloc1 h3 {
    font-size: 46px;
  }
  .text-bloc1 h4 {
    font-size: 24px;
    margin-bottom: 75px;
  }
  #savoir-plus {
    margin-bottom: 20px;
  }
  .scroll-downs {
    margin-bottom: 10px;
    margin-top: 75px;
  }
}
