/* body{
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: consolas;
} */

/* .container{
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
} */

.container1 .card {
  position: relative;
  cursor: pointer;
}
.container1 .content {
  margin: 0px;
  display: flex;
  flex-direction: column;
}
.container1 .card .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.container1 .card .face.face1 {
  position: relative;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.container1 .card:hover .face.face1 {
  background: #063AA9;
  transform: translateY(0);
}

.container1 .card .face.face1 .content {
  opacity: 1;
  transition: 0.5s;
}

.container1 .card:hover .face.face1 .content {
  opacity: 1;
}

.container1 .card .face.face1 .content img {
  max-height: 100px;
}

.container1 .card .face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  color: #063AA9;
  text-align: center;
  font-size: 1.5em;
}
.container1 .card:hover .face.face1 .content h3 {
  color: #fff;
}

.container1 .card .face.face2 {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  transform: translateY(-100px);
}

.container1 .card:hover .face.face2 {
  transform: translateY(0);
}

.container1 .card .face.face2 .content p {
  margin: 0;
  padding: 0;
}

.container1 .card .face.face2 .content a {
  margin: 15px 0 0;
  display: inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}

.container1 .card .face.face2 .content a:hover {
  background: #333;
  color: #fff;
}
@media screen and (min-width: 1450px) {
  .container1 .card .face {
    width: 400px;
    height: 200px;
    transition: 0.5s;
  }
}
@media screen and (max-width: 800px) {
  .container1 .card .face.face1 .content {
    opacity: 1;
  }
  .container1 .card .face.face1 {
    background: #fff;
    border: 1px solid #063AA9;
    transform: translateY(0);
  }
  .container1 .card .face.face2 {
    background: #063AA9;
    color: #fff;
    transform: translateY(0);
  }
  .container1 .card .face.face1 .content h3 {
    color: #063AA9;
  }
  .container1 .card .face.face1 .content img {
    color: #fff;
  }
  .container1 .card {
    margin-bottom: 20px;
  }
  .container1 p {
    line-height: inherit;
  }
  .container1 .card .face.face2 {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
  }
}
@media screen and (max-width: 400px) {
  .container1 .card {
    margin-left: 37px;
  }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
  .container1 .card {
    margin-left: 56px;
  }
}
