.container {
    float: right;
    height: 100%;
    overflow: hidden;
}

.dropdown-container {
    height: 100%;
    cursor: pointer;
    display: inline-block;
    padding: 0 12px;
    transition: all .3s;
}

.dropdown-container:hover {
    background: rgba(0,0,0,.045);
}

.dropdown-container > i {
    font-size: 16px;
    transform: none;
}

.dropdown-container > i svg {
    position: relative;
    top: 3px;
}

.full-name {
    color: #434e59;
    padding-left: 9px;
    font-weight: bold;
}

.trigger {
    font-size: 20px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}


