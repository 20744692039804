.container-sider {
    min-height: 100vh;
    z-index: 10;
    box-shadow: 2px 0 6px rgba(0,21,41,.35);
    background: #0032A0;
    overflow: auto;
    position: fixed;
    left: 0;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000080;
}

.content {
    margin: 88px 24px 24px 280px;
}

.footer {
    text-align: center;
    margin-left: 280px;
}

@media only screen and (max-width: 992px) {
    .content, .footer {
        margin-left: 100px;
    }
}