#card-realisation:hover {
  box-shadow: 3px 1px 13px -1px rgba(84,81,84,1);
  cursor: pointer;
}
#card-realisation {
    min-height: 490px;
}

.ant-card-meta-description {
    color: gray;
    font-size: 20px;
    text-align: center;
}

.ant-card-meta-detail > div:not(:last-child) {
    text-align: center;
}
.newslater {
    background-Color: #063AA9;
    height: 250px;
  }
#col2-newslater {

}
#newslater-h1 {

    color: white;
    font-size: 46px;
    margin-bottom: 0px;
}
.newslater .ant-input-search-button {
  border: 1px solid white;
  background-color: #063AA9;
}
@media screen and (max-width: 500px) {
    .newslater {
        height: 400px;
        background-Color: #063AA9;
    }
    #col2-newslater {
        text-align: center;
        margin-top: 10px;
        margin-left: 20px;
        margin-right: 20px;
    }
    #col1-newslater {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
    }
    #newslater-h1 {
        margin-top: 55px
    }
    .carousel-realisations {
        text-align: center;
        height: 465px;
        margin-bottom: 15px;
        line-height: 140px;
        background: transparent;
        overflow: hidden;
    }
}
@media screen and (max-width: 900px) and (min-width: 500px){
    .newslater {
        background-Color: #063AA9;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    #col2-newslater {

        margin-bottom: 50px;
        margin-right: 20px;
        margin-left: 20px;
        justify-content: center;
    }

  #col1-newslater {
    margin-left: 50px;
    margin-right: 50px;
  }


  .btn-newslater {
    margin-top: 10px;
  }
  .input-newslater {
    margin-top: 10px;
  }
  .carousel-realisations {
    text-align: center;
    height: 700px;
      margin-bottom: 15px;
    line-height: 160px;
    background: transparent;
    overflow: hidden;

  }
}

@media screen and (min-width: 900px) and (max-width: 1024px){
    .newslater {
        background-Color: #063AA9;
        text-align: center;
    }
    #col2-newslater {
        margin-top: 20px;
        margin-bottom: 50px;
        margin-right: 20px;
        margin-left: 20px;
        justify-content: center;
    }
    #col1-newslater {
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 20px;
    }
    .newslater .ant-input-group{

        margin-right: auto;
        margin-left: auto;
    }
}



  .newslater .ant-input-search-button {
    padding-left: 25px;
    padding-right: 25px;
  }


  @media screen and (min-width: 1024px){
      .newslater {
          display: flex;
          align-items: center;
      }
  }

    @media screen and (min-width: 1441px) and (max-width: 1630px)  {
        #card-realisation {
            min-height: 452px;
        }
    }

@media screen and (min-width: 1129px) and (max-width: 1441px)  {
    #card-realisation {
        min-height: 443px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1129px)  {
    #card-realisation {
        min-height: 433px;
    }
}

