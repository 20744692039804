/* Affichage de la page */

/* police et fond */
* {
  font-family: 'Century Gothic';
}
.equipes_partenaires {
  margin: auto;
  background-color: #fdfdfd;
}

/* Première partie */

/* Affichage fond, titre, noms et fonctions */
.equipe {
  position: relative;
  width: 100%;
}
.img-equipe {
  background-image: url('../assets/images/networkTeamTry2.webp');
  background-image: url('../assets/images/safari/networkTeamTry2.jpg');
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  position: relative;
  height: 400px;
  margin-top: -25px;
}
.equipe img {
  width: 100%;
  position: fixed;
  z-index: 0;
  top: 0;
  height: 750px;
}
.equipe h1 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
/* titre barre */
.hr {
  align-items: center;
  display: flex;
  text-align: center;
  color: #3242a8;
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.hr::before,
.hr::after {
  border-top: 0.0625em solid;
  content: '';
  flex: 1;
  margin: 0;
  left: -50%;
  background-color: #3242a8;
}
.hr::before {
  margin-right: 30px;
}
.hr::after {
  margin-left: 30px;
}

.decouvrez_equipe p {
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
  text-align: center;
  font-size: 16px;
}
@media screen and (min-width: 1450px) {
  .decouvrez_equipe p {
    width: 60%;
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
    font-size: 20px;
  }
}
.employe {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}
#name {
  font-size: 20px;
  text-align: center;
  margin-bottom: -10px;
  font-weight: bold;
}
#function {
  font-size: 15px;
  font-style: italic;
  line-height: 0.5 !important;
}

/* Seconde partie*/

/* Affichage de la liste des partenaires */
.partenaires h1 {
  margin-top: 30px;
  margin-bottom: 50px;
}
.partenaires_liste {
  margin-left: 110px;
  margin-right: 110px;
}
@media screen and (min-width: 900px) {
  #row-partenaires {
    margin: 60px;
  }
}

/* Affichage d'une card partenaire */
.card-partenaires {
  background-color: white;
  height: 550px;
}
.card-partenaires:hover {
  box-shadow: 3px 1px 13px -1px rgba(84, 81, 84, 1);
  cursor: pointer;
}
#part-name {
  text-align: center;
  color: gray;
  font-weight: bold;
  margin: 0;
}
#part-content {
  text-align: center;
  color: gray;
  font-size: 15px;
  padding: 20px;
}
@media screen and (max-width: 500px) {
  .equipe img {
    height: 700px;
    width: max-content;
  }
  .equipe h1 {
    font-size: 30px;
  }
  .hr {
    font-size: 30px;
  }
  .decouvrez_equipe p {
    font-size: 15px;
  }
  .partenaires_liste {
    margin-left: 30px;
    margin-right: 30px;
  }
  #name {
    font-size: 15px;
  }
  #function {
    font-size: 12px;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .equipe img {
    height: 400px;
  }
}
@media screen and (max-width: 800px) {
  .partenaires_liste {
    margin-left: 25px;
    margin-right: 25px;
  }
}
@media screen and (min-width: 1450px) {
  .partenaires_liste {
    margin-top: 100px;
    margin-left: 300px !important;
    margin-right: 300px;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 732px) {
  .card-partenaires {
    height: 646px;
  }
}
