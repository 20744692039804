.text-bloc2 {
    text-align: center;
    height: calc(100% - 200px);
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logiciel-img, .logiciel-img2 {
    margin: auto;
    display: block;
}

@media screen and (max-width: 500px) {
    .logiciel-img, .logiciel-img2 {
        width: 375px;
    }
}
@media screen and (max-width: 900px) and (min-width: 500px) {
    .logiciel-img2 {
        margin-top: 50px;
    }
}

@media screen and (min-width: 900px) and (max-width: 1214px) {
    .logiciel-img, .logiciel-img2 {
        margin: auto;
        display: block;
        width: 500px;
    }
}