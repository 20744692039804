.center-para{
  text-align: center;
}

#icon-social {
  margin: 12px;
  font-size: 30px;
  color: #001529;
  text-align: center;
}
.form-contact {
  width: 50%;
  margin: auto;
}

#col-contact {
  text-align: center;
}
#col-contact p {
  margin: 0px;
}
#col-contact img {
  margin-bottom: 10px;
}
.row-contact {
  margin-top: 70px;
  margin-bottom: 70px;
  margin-left: 150px;
  margin-right: 150px;
}
@media screen and (min-width: 1450px) {
  .row-contact {
    margin-left: 300px;
  margin-right: 300px;
  }
}
@media screen and (max-width: 900px) {
  .form-contact {
    width: 80%;
  }
  .row-contact {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
  }
  #col-contact {
    margin-bottom: 60px;
  }
}
.input-contact {
  font-size: 16px;
}