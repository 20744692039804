/* Affichage de la page, police, fond titre et texte non spécifique */
* {
  font-family: 'Century Gothic';
}
.recrutement {
  background-color: #fdfdfd;
}
.recrutement p {
  margin-top: 10px;
  font-size: 15px;
  text-align: center;
}
.recrutement h4 {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 25px;
}

/* Acceuil, choix entre offres d'emplois et candidature spontanée */
/* Sous titre apparait au survol */

.wrapper1 {
  position: relative;
  max-width: 100%;
  cursor: pointer;
}
.wrapper1 img {
  width: 100%;
  height: 780px;
}
@media screen and (min-width: 1450px) {
  .wrapper1 img {
    width: 100%;
    height: 1050px;
  }
  .overlay-recrutement h1 {
    font-size: 70px;
  }
}

.contrast:hover {
  filter: contrast(70%);
  -webkit-filter: contrast(70%);
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.overlay-recrutement h1 {
  padding: 20px;
  width: max-content;
  background-color: transparent;
  color: white;
  position: absolute;
  font-size: 40px;
  font-weight: bold;
  top: 40%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
  .overlay-recrutement {
    padding: 0px;
  }
  .overlay-recrutement h1 {
    top: 50%;
    font-size: 30px;
  }
}
.overlay2 {
  position: absolute;
  max-width: 100%;
  width: max-content;
  padding: 20px;
  background-color: transparent;
  color: white;
  font-size: 20px;
  font-weight: bold;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.wrapper1:hover .overlay2 {
  opacity: 1;
}
.wrapper1:hover img {
  background-color: rgba(0, 0, 0, 0.4);
}
@media screen and (max-width: 800px) {
  .overlay-recrutement {
    font-size: 30px;
    text-align: center;
  }
  .overlay-recrutement h1 {
    top: 50%;
  }
  .wrapper1 img {
    width: 100%;
    height: 500px;
  }
}

.img-emp {
  background-image: url('../assets/images/espaceemploi.webp');
  background-image: url('../assets/images/safari/espaceemploi.png');
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  position: relative;
  height: 780px;
  margin-top: -25px;
}
.img-cand {
  background-image: url('../assets/images/candidatures.webp');
  background-image: url('../assets/images/safari/candidatures.png');
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  position: relative;
  height: 780px;
  margin-top: -25px;
}
@media screen and (min-width: 1450px) {
  .img-emp,
  .img-cand {
    height: 1050px;
  }
}
@media screen and (max-width: 400px) {
  .img-emp,
  .img-cand {
    height: 430px;
  }
  .img-emp {
    margin-top: 0px;
  }
}
@media screen and (min-width: 400px) and (max-width: 500px) {
  .img-emp,
  .img-cand {
    height: 395px;
  }
  .img-emp {
    margin-top: 0px;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .img-emp,
  .img-cand {
    height: 540px;
  }
  .img-emp {
    margin-top: 0px;
  }
}
/* Titre de chaque vues, liste offres, détail offre et candidature spontanée */
.view_title {
  text-align: center;
  font-size: 500%;
  background-color: #454241;
  color: white;
  margin: 0;
}
@media screen and (max-width: 800px) {
  .view_title {
    font-size: 40px;
  }
}

/* Sous titre de chaque vues, liste offres et candidature spontanée */
.view_desc {
  text-align: center;
  background-color: #454241;
  color: white;
  margin: 0;
  margin-bottom: 20px;
  font-size: 20px;
}
@media screen and (max-width: 800px) {
  .view_desc {
    font-size: 15px;
  }
}

/* Vue listes des offres */
.offres {
  background-color: #fdfdfd;
}
.img-emploi {
  background-image: url('../assets/images/espace_emploi1.1.webp');
  background-image: url('../assets/images/safari/espace_emploi1.1.png');
  background-position: center;
  background-size: cover;
  filter: brightness(60%);
  width: 100%;
  position: relative;
  top: 0;
  height: 400px;
  margin-top: -25px;
}

.title_offres_emplois {
  position: relative;
}
.title_offres h1 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: white;
  position: absolute;
  top: 55%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
.title_offres h3 {
  font-size: 20px;
  width: max-content;
  text-align: center;
  color: white;
  position: absolute;
  top: 65%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
.liste_offres {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 100px;
  margin-right: 100px;
  background-color: #fdfdfd;
}
@media screen and (min-width: 1450px) {
  .liste_offres {
    margin-left: 200px;
    margin-right: 200px;
  }
  .title_offres_emplois h1 {
    top: 200px;
  }
  .title_candidature h1 {
    top: 200px;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .title_offres h3 {
    font-size: 20px;
    width: 70%;
    top: 230px;
  }
  .title_offres h1 {
    font-size: 35px;
    top: 150px;
    width: 100%;
  }
  .title_candidature h1 {
    font-size: 35px;
    top: 150px;
    width: 100%;
  }
  .title_offres_emplois img {
    height: 1000px;
  }
}
@media screen and (max-width: 500px) {
  .title_offres h3 {
    font-size: 20px;
    width: auto;
    margin-top: 20px;
    top: 260px;
  }
  .title_offres_emplois img {
    height: 700px;
    width: max-content;
  }
  .title_offres h1 {
    font-size: 35px;
    top: 50%;
    width: 100%;
  }
  .filtres-emplois .ant-input-search {
    margin-bottom: 20px;
  }
  .liste_offres {
    justify-content: center;
  }
}
#tag {
  padding: 5px;
}
.filtres-emplois {
  margin-left: 100px;
}
@media screen and (min-width: 1450px) {
  .filtres-emplois {
    margin-left: 200px;
  }
}
.filtres-emplois .ant-input-search-button {
  background-color: #3242a8;
}
.filtres-emplois button {
  background-color: #3242a8;
}
.filtres-emplois button:hover {
  background-color: #4287f5;
}
@media screen and (max-width: 500px) {
  .filtres-emplois {
    margin-left: 10px;
    margin-right: 10px;
  }
  .liste_offres {
    margin-left: 10px;
    margin-right: 10px;
  }
}
/* Card offre emploi */
#poste-date {
  font-size: 15px;
  font-style: italic;
  margin-bottom: 0px;
}
#poste-title {
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  flex-wrap: wrap;
}

.dispo,
.indispo {
  margin-bottom: 30px;
}
.dispo {
  background-color: white;
}
.dispo:hover {
  box-shadow: 3px 1px 13px -1px rgba(84, 81, 84, 1);
}
.indispo {
  background-color: #c0c4c1;
}
.indispo:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.hvrbox,
.hvrbox * {
  box-sizing: border-box;
}
.hvrbox {
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.hvrbox img {
  max-width: 100%;
}
.hvrbox .hvrbox-layer_bottom {
  display: block;
  margin: auto;
}
.hvrbox .hvrbox-layer_top {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  color: #fff;
  padding: 15px;
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.indispo:hover .hvrbox-layer_top,
.hvrbox.active .hvrbox-layer_top {
  opacity: 1;
}
.indispo:hover .hvrbox img {
  filter: contrast(50%);
}
.hvrbox .hvrbox-text {
  text-align: center;
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.hvrbox .hvrbox-text_mobile {
  font-size: 15px;
  border-top: 1px solid rgb(179, 179, 179);
  border-top: 1px solid rgba(179, 179, 179, 0.7);
  margin-top: 5px;
  padding-top: 2px;
  display: none;
}
.hvrbox.active .hvrbox-text_mobile {
  display: block;
}
.hvrbox-layer_bottom {
  padding: 10px;
}
.ant-card {
  overflow: hidden;
}
.dispo .ant-card-body {
  padding: 0;
}
.indispo .ant-card-body {
  padding: 0;
}
@media screen and (max-width: 800px) {
  .indispo .hvrbox-layer_top {
    opacity: 1;
  }
}

/* Affichage html offre strapi dans les cards */
.poste {
  margin-top: 0px;
  border-top: solid;
  border-bottom: solid;
  padding-top: 10px;
  padding-bottom: 10px;
  border-width: 1px;
  border-color: whitesmoke;
  text-align: center;
}
.offre_strapi {
  margin: 0;
  background-color: transparent;
}
.offre_strapi h1 {
  color: black;
  padding: 10px;
  font-size: 20px;
  margin: 0px;
  background-color: transparent;
}
.offre_strapi p {
  font-size: 15px;
  text-align: center;
}

/* Affichage des filtres-emplois */
.filtres-emplois {
  margin-top: 50px;
}

.offres-paginate {
  text-align: center;
  padding-bottom: 50px;
}

/* Vue offre détail */
/* image en fond du titre de l'offre */
.intitule_offre {
  position: relative;
  max-width: 100%;
}
.offredetail img {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  height: 750px;
  filter: contrast(50%);
}
.intitule_offre_title {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 50px;
  color: white;
  margin: 0;
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .intitule_offre_title {
    font-size: 40px;
  }
  .intitule_offre img {
    height: 400px;
  }
}
@media screen and (max-width: 500px) {
  .intitule_offre img {
    height: 200px;
  }
  .intitule_offre_title {
    font-size: 30px;
  }
}

/* sous titre barre */
#offre_barre {
  position: relative;
  color: #063aa9;
  overflow: hidden;
  text-align: center;
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}
#offre_barre:before,
#offre_barre:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 2px;
  content: '\a0';
  background-color: #063aa9;
}
#offre_barre:before {
  margin-left: -55%;
  text-align: right;
}
#offre_barre:after {
  margin-left: 5%;
}
#offre_barre_appli {
  position: relative;
  color: #063aa9;
  overflow: hidden;
  text-align: center;
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}
#offre_barre_appli:before,
#offre_barre_appli:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 2px;
  content: '\a0';
  background-color: #063aa9;
}
#offre_barre_appli:before {
  margin-left: -55%;
  text-align: right;
}
#offre_barre_appli:after {
  margin-left: 5%;
}
#sous-titre {
  text-align: center;
  font-weight: bold;
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  #offre_barre {
    font-size: 30px;
  }
  #offre_barre_appli {
    font-size: 30px;
  }
  #offre_barre_appli:before {
    margin-left: -49%;
    text-align: right;
  }
  #offre_barre_appli:after {
    margin-left: 28%;
  }
}
@media screen and (max-width: 400px) {
  #offre_barre {
    font-size: 30px;
  }
  #offre_barre_appli {
    font-size: 30px;
  }
  #offre_barre_appli:before {
    margin-left: -50%;
    text-align: right;
  }
  #offre_barre_appli:after {
    margin-left: 11%;
  }
}
/* Affichage HTML contenue dans l'offre récupéré de STRAPI */
.texteoffre {
  margin-left: 150px;
  margin-right: 150px;
}
.texteoffre h2 {
  background-color: transparent;
  color: black;
  font-size: 30px;
  text-align: left;
}
.texteoffre p {
  background-color: transparent;
  font-size: 20px !important;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: left;
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .texteoffre h1 {
    font-size: 20px;
  }
  .texteoffre p {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 500px) {
  .texteoffre h1 {
    font-size: 20px;
  }
  .texteoffre p {
    font-size: 15px !important;
    margin-left: 20px;
    margin-right: 15px;
  }
}

/* Affichage vue candidature */

.img-candidature {
  background-image: url('../assets/images/candidaturespontanee.webp');
  background-image: url('../assets/images/safari/candidaturespontanee.png');
  background-position: center;
  background-size: cover;
  filter: brightness(90%);
  width: 100%;
  position: relative;
  height: 400px;
  margin-top: -25px;
}

.title_candidature h1 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: white;
  position: absolute;
  top: 200px;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
.title_candidature h3 {
  font-size: 20px;
  width: max-content;
  text-align: center;
  color: white;
  position: absolute;
  top: 250px;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
@media screen and (max-width: 900px) {
  .title_candidature h1 {
    font-size: 35px;
  }
}
@media screen and (max-width: 500px) {
  .title_candidature h3 {
    font-size: 15px;
    margin-top: 10px;
  }
  .title_candidature h1 {
    top: 40%;
  }
  .title_candidature img {
    height: 200px;
  }
}
@media screen and (min-width: 500px) and (max-width: 900px) {
  .title_candidature img {
    height: 400px;
  }
  .title_candidature h1 {
    font-size: 35px;
    width: max-content;
    top: 150px;
  }
  .texteoffre {
    margin-left: 50px;
    margin-right: 50px;
  }
}

/* Formulaire de candidature sur les 2 vues */
.formulaire_candidature {
  width: 700px;
  margin: auto;
}
.soumettre {
  margin-top: 25px;
  margin-bottom: 50px;
}
@media screen and (max-width: 800px) {
  .soumettre {
    width: 100%;
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .formulaire_candidature {
    width: 300px;
  }
  .soumettre {
    width: 100%;
  }
}
#errors {
  font-size: 10px;
  margin: 0;
  padding: 0;
  color: red;
  text-align: left;
}
@media screen and (min-width: 1450px) {
  .liste_offres {
    margin-left: 200px;
    margin-right: 200px;
  }
  .title_offres_emplois h1 {
    top: 200px;
  }
  .title_candidature h1 {
    top: 150px;
  }
}

/* Retour vers espace emplois dans le détail d'une offre */
#link_content {
  background-color: #063aa9;
  color: white;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 20px;
  text-align: center;
  font-size: 20px;
}
#link_title {
  background-color: #063aa9;
  color: white;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 20px;
  text-align: center;
  font-size: 30px;
}
@media screen and (max-width: 500px) {
  #link_content {
    font-size: 15px;
  }
  #link_title {
    font-size: 15px;
  }
  .texteoffre {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (min-width: 900px) and (max-width: 14089px) {
  .img-emp,
  .img-cand {
    height: 990px;
  }
}
