* {
  font-family: 'Century Gothic';
}
.blog {
  background-color: #fdfdfd;
}
.loading {
  text-align: center;
} /* image et texte header blog */
.img-blog {
  background-image: url('../assets/images/actualite2.webp');
  background-image: url('../assets/images/safari/actualite2.jpg');
  background-position: center;
  background-size: cover;
  filter: brightness(50%);
  width: 100%;
  position: relative;
  height: 400px;
  margin-top: -25px;
}
.title_blog h1 {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  color: white;
  position: absolute;
  top: 40%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
.title_blog h3 {
  font-size: 20px;
  width: max-content;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}

/* Affichage des filtres */
.filtres_blog {
  padding-top: 50px;
  margin-top: 0px !important;
  margin-left: 150px;
  margin-right: 150px;
}
@media screen and (max-width: 900px) {
  .filtres_blog {
    margin-left: 30px;
    margin-right: 30px;
  }
}

/* Affichage liste articles */
.onepost {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
  margin-bottom: 30px;
}
@media screen and (min-width: 1450px) {
  .onepost {
    margin-left: 200px;
    margin-right: 200px;
  }
}
@media screen and (max-width: 900px) {
  .onepost {
    margin-left: 30px;
    margin-right: 30px;
  }
}

/* card post */
.figure {
  text-align: center;
}
.figure figcaption {
  font-style: italic;
  font-size: 15px;
}

#card-article {
  padding: 20px;
  height: 500px;
  overflow: hidden;
}
@media screen and (min-width: 1450px) {
  #card-article {
    height: 800px;
  }
}
#title-article {
  font-weight: bold;
  text-align: left;
  margin-top: -10px;
}
#card-article:hover {
  box-shadow: 3px 1px 13px -1px rgba(84, 81, 84, 1);
  cursor: pointer;
}
#content-article {
  text-align: left;
  font-size: 16px;
}
/* contenue du post strapi */
.artcontent {
  height: 150px;
}
.artcontent li {
  padding: auto;
}
.artcontent h1 {
  font-size: 30px;
}
.artcontent p {
  font-size: 20px;
  text-align: left !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Affichage vue détail article */
/* .blogpost {
  margin-bottom: 40px;
  margin-top: 40px;
}
.blogpost img {
  width: 600px; 
  margin: auto ;
  display: block;
}
.blogpost h1 {
  font-size: 30px;
  text-align: center;
} */
.header-article {
  display: flex;
  justify-content: center;
  margin: 50px;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .header-article {
    flex-direction: column;
  }
}
/* Affichage du contenue html du post" */
.poststrapi {
  width: 60%;
  margin: auto;
  padding: 10px;
  margin-top: 100px;
  margin-bottom: 40px;
  text-align: justify;
}
#nextpost {
  text-align: right;
  margin-right: 30px;
  font-size: 20px;
  color: white;
}
#prevpost {
  text-align: left;
  margin-left: 30px;
  font-size: 20px;
  color: white;
}
.linkposts {
  background-color: #063aa9;
  padding-top: 30px;
  padding-bottom: 30px;
}

/* pagination */
#root > div.blog > div.ant-row.ant-row-center {
  padding-bottom: 50px;
}

/* responsive */
@media screen and (max-width: 500px) {
  .title_blog img {
    height: 300px;
  }
  .title_blog h1 {
    top: 40%;
    font-size: 35px;
  }
  .title_blog h3 {
    font-size: 15px;
    margin-top: 10px;
  }
  .onepost a {
    width: 100%;
  }
  .filtres_blog div.ant-col-xs-16:nth-child(4) {
    margin-top: 20px;
  }
  .filtres_blog div.ant-col-xs-6 {
    text-align: center;
  }
  #nextpost,
  #prevpost {
    font-size: 10px;
  }
  .blogpost img,
  .poststrapi {
    width: 95%;
    margin-top: 50px;
    font-size: 15px;
  }
  .title_blog img {
    height: 200px;
  }
}
@media screen and (min-width: 500px) and (max-width: 800px) {
  #nextpost,
  #prevpost {
    font-size: 20px;
    width: max-content;
  }
  .blogpost img,
  .poststrapi {
    width: 80%;
  }
  .title_blog img {
    height: 400px;
  }
}
.blog-paginate {
  text-align: center;
  padding-bottom: 50px;
  padding-top: 26px;
}
